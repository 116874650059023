.remove-wallet-dialog {
  .MuiDialog-paperWidthSm {
    background-color: transparent;
    border-radius: 16px !important;
    width: fit-content;
  }

  .remove-wallet-head {
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;
    margin-bottom: 24px;
  }
}
