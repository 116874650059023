.connect-wallet-dialog {
  .MuiDialog-paperWidthSm {
    background-color: transparent;
    border-radius: 16px !important;
    width: fit-content;
  }
  .dialog-content > div {
    display: flex;
    align-items: center;
  }
  .wallet-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 150px;
    height: 150px;
    background: rgba(255, 255, 255, 0.05);
    border: 1px solid rgba(255, 255, 255, 0.12);
    border-radius: 20px;
    margin-right: 24px;
    cursor: pointer;

    &:last-child {
      margin: 0;
    }

    &:hover {
      border: 2px solid #F55F7F;
    }

    span {
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: #C5C7D3;
      margin-top: 16px;
    }
  }

  .wallet-loading {
    display: flex;
    flex-direction: column;
    span {
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: #C5C7D3;
    }

    &-top {
      display: flex;
      align-items: center;
      img {
        margin-right: 8px;
      }
    }

    &-bottom {
      margin-top: 24px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }
}
