.send-dialog {
  .MuiDialog-paperWidthSm {
    background-color: transparent;
    border-radius: 16px !important;
    width: fit-content;
  }

  .dialog-content-wrapper {
      width: 100%;
  }
  .dialog-content {
    padding-top: 16px;
  }
  .dialog-content-row {
    display: flex;
    align-items: flex-start;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 32px;
    span {
      color: #888686;
      display: inline-block;
      width: 140px;
    }
    div {
      max-width: calc(100% - 140px);
      font-weight: 600;
      color: #FFFFFF;
    }
  }

  .send-title {
    font-weight: 600;
    font-size: 24px;
    line-height: 100%;
    color: #FFFFFF;
    text-align: center;
  }

  .advanced-wrapper {
    h3 {
      font-size: 16px;
      line-height: 24px;
      color: #888686;
      display: flex;
      align-items: center;
      justify-content: space-between;
      img {
        cursor: pointer;
      }
    }
    & > div {
      display: flex;
      align-items: center;
      & > div:first-child {
        margin-right: 10px;
      }
    }
  }

  .success-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    h3 {
      font-weight: 600;
      font-size: 24px;
      line-height: 32px;
      color: #FFFFFF;
      margin: 24px 0 10px;
    }
    span {
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: #FFFFFF;
    }
  }
}
