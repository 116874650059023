body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
* {
  padding: 0;
  margin: 0;
}

ul,
ol {
  margin: 0;
  list-style: none;
}

a {
  transition: color 0.25s, fill 0.25s;
  color: inherit;
  text-decoration: none;
}

::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar-thumb {
  border-radius: 3px;
}

::-webkit-scrollbar-track-piece {
  background-color: transparent;
}

::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, 0.5);
}

::-webkit-scrollbar-corner {
  display: block;
}

.MuiDialog-paper {
  .dialog-wrapper {
    position: relative;
    background: #2A2A2A;
    box-shadow: 0px 0px 0px 1px rgba(255, 255, 255, 0.14), 0px 52px 37px -20px rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(100px);
    border-radius: 20px!important;
    min-width: 556px;
    & > div {
      padding: 25px 35px;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    @media (max-width: 576px) {
      width: unset;
      min-width: 300px;
      & > div {
        padding: 24px;
      }
    }
  }

  .dialog-header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;

    & > h3 {
      font-weight: 600;
      font-size: 24px;
      line-height: 16px;
      color: #ffffff;
    }

    & > img {
      width: 24px;
      height: 24px;
      cursor: pointer;
    }
  }

  .dialog-content {
    width: 100%;
    max-height: 470px;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .dialog-loading {
    position: absolute;
    background: rgba(0,0,0,0.5);
    z-index: 10;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 50%;
    }
  }

  .dialog-footer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 24px;
    button {
      margin-right: 16px;
    }
    button:last-child {
      margin-right: 0;
    }
  }
}

.MuiSelect-select {
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05)), #212333;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.12);
  padding: 0 8px;
  display: flex;
  align-items: center;
  border-radius: 70px;
  font-weight: 600;
  font-size: 20px;
  line-height: 36px;
  color: #C5C7D3;
}
.MuiSelect-icon {
  color: #C5C7D3;
}

.MuiOutlinedInput-notchedOutline {
  border: none;
}

.MuiMenu-paper {
  border-radius: 20px;
  overflow: hidden;
}
.MuiMenu-list {
  background: #3F3F3F;
  border: 4px solid rgba(0, 0, 0, 0.12);
  border-radius: 20px;
  padding: 0!important;
  overflow: hidden;
}

.MuiListItem-root.Mui-selected, .MuiListItem-root.Mui-selected:hover {
  background: rgba(255, 255, 255, 0.1) !important;
}

.MuiMenuItem-root {
  padding: 16px 20px!important;
  border-bottom: 1px solid rgba(255, 255, 255, 0.08)!important;
  &:last-child {
    border: none!important;
  }
  div {
    padding: 0!important;
  }
}
