.create-wallet-dialog {
  .MuiDialog-paperWidthSm {
    background-color: transparent;
    border-radius: 16px !important;
    width: fit-content;
  }

  .dialog-content-wrapper {
      width: 100%;
  }

  .create-wallet-step-wrapper {
    display: flex;
    align-items: center;
    span {
      display: inline-block;
      width: 10px;
      height: 10px;
      background: #FFFFFF;
      opacity: 0.15;
      border-radius: 5px;
      margin-left: 10px;
      &:first-child {
        margin: 0;
      }
      &.select-step {
        background: #A0B5FF!important;
        opacity: 1!important;
      }
    }
  }
  .create-wallet-header {
    font-weight: 600;
    font-size: 32px;
    line-height: 40px;
    color: #FFFFFF;
    text-align: center;
    margin-bottom: 30px;
  }

  .agree-wrapper {
    .MuiCheckbox-root {
      padding: 0 5px 0 0;
    }
  }

  .create-wallet-remind {
    & > div {
      background: rgba(255, 255, 255, 0.05);
      border-radius: 12px;
    }
  }

  .seed-words {
    display: grid;
    grid-template-columns: 25% 25% 25% 25%;
    row-gap: 10px;
    margin-top: 28px;

    &-item {
      display: flex;
      align-items: center;
      font-style: normal;
      font-size: 14px;
      line-height: 20px;
      font-weight: 600;
      color: #ffffff;
      margin-bottom: 28px;

      span {
        font-weight: 500;
        color: #738092;
        margin-right: 20px;
      }
    }
  }

  &__seed-word {
    display: inline-flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    padding: 4px 14px !important;
    height: 28px;
    margin: 0 5px 12px 0 !important;
    text-align: center;
    cursor: move;
    border-radius: 8px;
    color: #A0B5FF;
    font-weight: 600;
    font-size: 14px;
    box-sizing: border-box;
    &:hover{
      color: #fff;
    }
    &--sorted {
      cursor: pointer;
      margin: 6px;
    }

    &--selected {
      opacity: 0.5;
      &:hover{
        color: #A0B5FF;
        opacity: 0.5;
      }
    }

    &--dragging {
      margin: 0;
    }

    &--empty {
      background-color: transparent;
      cursor: default;

      &:hover,
      &:active {
        background-color: transparent;
        cursor: default;
        box-shadow: none !important;
        border: 1px dashed #ffac3d !important;
      }
    }

    &--hidden {
      display: none !important;
    }

    &--drop-hover {
      background-color: transparent;
      border-color: transparent;
      color: transparent;
    }
  }

  .select-words-wrapper {
    margin: 24px 0;
    column-gap: 10px;
    grid-template-columns: 23% 23% 23% 23%;
  }

  .select-words-item {
    display: flex;
    align-items: center;
    background: #343440;
    color: #A0B5FF;
    box-shadow: 0px 1px 0px rgba(255, 255, 255, 0.15);
    padding: 10px;
    span {
      margin-right: 10px;
    }
    &-content {
      cursor: pointer;
      box-shadow: 0px 1px 0px #A0B5FF;
      &:hover {
        color: #fff;
        box-shadow: 0px 1px 0px #FFFFFF;
      }
    }
  }

  .success-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    h3 {
      font-weight: 600;
      font-size: 24px;
      line-height: 32px;
      color: #FFFFFF;
      margin: 24px 0 10px;
    }
    span {
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: #FFFFFF;
      margin-bottom: 8px;
    }
  }
}
