.delegate-dialog {
  .MuiDialog-paperWidthSm {
    background-color: transparent;
    border-radius: 16px !important;
    width: fit-content;
  }

  .dialog-content-validator {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 16px;
    line-height: 24px;
    color: #888686;
    margin: -32px 0 8px;
    div {
      font-weight: 600;
      color: #FFFFFF;
    }
  }

  .dialog-content-wrapper {
    width: 100%;
  }
  .dialog-content {
    padding-top: 16px;
  }
  .dialog-content-row {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 32px;
    span {
      color: #888686;
      display: inline-block;
      width: 140px;
    }
    div {
      max-width: calc(100% - 140px);
      font-weight: 600;
      color: #FFFFFF;
    }
  }
}
