.address-code-dialog {
  .MuiDialog-paperWidthSm {
    background-color: transparent;
    border-radius: 16px !important;
    width: fit-content;
  }

  .dialog-content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .address {
    font-size: 16px;
    line-height: 24px;
  }

  .code-wrapper {
    border-radius: 8px;
    background: #fff;
    padding: 8px;
    margin: 24px 0;
  }
}
