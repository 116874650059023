.unDelegate-dialog {
  .MuiDialog-paperWidthSm {
    background-color: transparent;
    border-radius: 16px !important;
    width: fit-content;
  }

  .unDelegate-remind {
    margin-bottom: 16px;
    & > div {
      background: rgba(255, 255, 255, 0.05);
      border-radius: 12px;
    }
  }

  .unDelegate-locked-remind {
    margin-bottom: 16px;
    & > div {
      background: rgba(255, 0, 70, 0.2);
      border-radius: 12px;
    }
  }

  .dialog-content-row {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 32px;
    span {
      color: #888686;
      display: inline-block;
      width: 140px;
    }
    div {
      max-width: calc(100% - 140px);
      font-weight: 600;
      color: #FFFFFF;
    }
  }
}
