.select-wallet-dialog {
  .MuiDialog-paperWidthSm {
    background-color: transparent;
    border-radius: 16px !important;
    width: 900px;
    max-width: unset;
  }

  .select-wallet-wrapper {
  }
}
