.history-dialog {
  .MuiDialog-paperWidthSm {
    background-color: transparent;
    border-radius: 16px !important;
    width: fit-content;
  }

  .dialog-content-wrapper {
    padding-top: 0 !important;
    margin-top: -25px !important;
  }
}
