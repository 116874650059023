.edit-wallet-dialog {
  .MuiDialog-paperWidthSm {
    background-color: transparent;
    border-radius: 16px !important;
    width: fit-content;
  }

  .edit-wallet-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 32px;
    span {
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: #888686;
    }

    & > div {
      display: flex;
      align-items: center;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: #FFFFFF;
      div {
        display: flex;
        align-items: center;
      }
      svg {
        color: #A0B5FF;
        margin-left: 12px;
        cursor: pointer;
      }
    }
  }
  .edit-wallet-name {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;
    display: flex;
    flex-direction: column;
    align-items: flex-start!important;
    input {
      background: rgba(255, 255, 255, 0.05);
      border-radius: 8px;
      padding: 10px 18px;
      margin-top: 12px;
      width: 100%;
      border: none;
      font-weight: 600;
      font-size: 24px;
      line-height: 32px;
      color: #ffffff;
      cursor: pointer;
      outline: none;
      &::placeholder {
        color: #738092;
      }
    }
  }
}
