.restore-wallet-dialog {
  .MuiDialog-paperWidthSm {
    background-color: transparent;
    border-radius: 16px !important;
    width: fit-content;
  }

  .dialog-content-wrapper {
      width: 100%;
  }

  .restore-wallet-step-wrapper {
    display: flex;
    align-items: center;
    span {
      display: inline-block;
      width: 10px;
      height: 10px;
      background: #FFFFFF;
      opacity: 0.15;
      border-radius: 5px;
      margin-left: 10px;
      &:first-child {
        margin: 0;
      }
      &.select-step {
        background: #A0B5FF!important;
        opacity: 1!important;
      }
    }
  }
  .restore-wallet-header {
    font-weight: 600;
    font-size: 32px;
    line-height: 40px;
    color: #FFFFFF;
    text-align: center;
    margin-bottom: 30px;
  }

  .restore-wallet-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 32px;

    &-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      width: 148px;
      height: 120px;
      background: linear-gradient(225.45deg, rgba(111, 119, 189, 0.22) 4.48%, rgba(111, 119, 189, 0.1) 107.71%);
      border: 1px solid rgba(255, 255, 255, 0.12);
      border-radius: 20px;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: #738092;
      span {
        margin-top: 16px;
      }
      &.selected {
        background: linear-gradient(225.45deg, rgba(111, 119, 189, 0.18) 4.48%, rgba(111, 119, 189, 0.07) 107.71%);
        border: 2px solid #A0B5FF;
        color: #FFFFFF;
      }
    }
  }

  .agree-wrapper {
    .MuiCheckbox-root {
      padding: 0 5px 0 0;
    }
  }

  .restore-wallet-remind {
    & > div {
      background: rgba(255, 255, 255, 0.05);
      border-radius: 12px;
    }
  }

  .success-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    h3 {
      font-weight: 600;
      font-size: 24px;
      line-height: 32px;
      color: #FFFFFF;
      margin: 24px 0 10px;
    }
    span {
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: #FFFFFF;
      margin-bottom: 8px;
    }
  }
}
