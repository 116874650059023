.lock-delegate-dialog {
  .MuiDialog-paperWidthSm {
    background-color: transparent;
    border-radius: 16px !important;
    width: fit-content;
  }

  .lock-delegate-title {
    font-weight: 700;
    font-size: 90px;
    line-height: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    color: #FFFFFF;
    margin-top: 32px;

    span {
      margin-left: 10px;
      font-weight: 500;
      font-size: 43px;
      line-height: 60px;
      color: #888686;
    }
  }

  .lock-delegate-remind {
    & > div {
      background: rgba(255, 255, 255, 0.05);
      border-radius: 12px;
    }
  }

  .dialog-content-row {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 32px;
    span {
      color: #888686;
      display: inline-block;
      width: 140px;
    }
    div {
      max-width: calc(100% - 140px);
      font-weight: 600;
      color: #FFFFFF;
    }
  }
}
